import { RGBAColor } from "./Common";
import { CashPaymentSetting } from "./transaction";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface RestaurantBankAccount {
  accountName: string;
  accountNumber: number;
  bsb: number;
}

export interface Restaurant {
  name: string;
  description: string;
  zoneId: string;
  stripe_account?: string;
  stateName: string;
  countryId: string;
  paymentProviderId: string;
  businessName?: string;
  statementDescriptor?: string;
  customerNameDisplay?: "firstName";
  postcodeMandatory?: boolean;
  ABN?: string;
  enabled: boolean;
  geometry?: {
    location?: { lat: number; lng: number };
    image?: string;
    link: string;
  };
  startingDay?: {
    enabled: boolean;
    day: number;
  };
  posPaymentSetting?: any;
  imageMapURL?: string;
  address: string;
  reservationEmail: string;
  customerFeedback?: any;
  maxNoOfBookingDays?: number;
  minGraphHeightPaxVenue?: number;
  minGraphHeightLiveRevenue?: number;
  useMap?: boolean;
  postcode: string;
  city: string;
  onlinePayment?: { americanExpressFees?: string; domesticCardsFees?: string; internationalCardsFees?: string; otherFees?: string };
  reservationPhoneNumber?: string;
  website?: string;
  CCTV?: string;
  cmsLink?: string;
  _key?: string;
  enableGCRedemption?: boolean;
  enableVoucherRedemption?: boolean;
  tillCashupPeriodType: RestaurantTillCashupPeriodType;
  nextCourseBasedOnOrderStatus?: boolean;
  cashPaymentSetting?: CashPaymentSetting;
}

export interface Restaurants {
  [restaurantId: string]: Restaurant;
}

export enum RestaurantTillCashupPeriodType {
  ByDay = 1,
  ByMealPeriod = 2,
}

export type RestaurantBranding = {
  bookingHubSummary?: string;
  bookingHubTitle?: string;
  fontFamily?: string;
  headerColor?: string | RGBAColor;
  headerColorOrderingApp?: string | RGBAColor;
  highlightBtnColor?: string | RGBAColor;
  pdfLogo?: string;
  restaurantBookingHubLogo?: string;
  restaurantBookingWidgetLogo?: string;
  restaurantFeature?: string;
  restaurantId?: string;
  restaurantLogo?: string;
  restaurantLogoBase64Image?: string;
  restaurantMoaHeaderLogo?: string;
  restaurantPdfLogo?: string;
  restaurantSplash?: string;
};
