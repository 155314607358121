import Stripe from "stripe";
import { Orders } from "./Order";
import { Mx51TransactionCompletionResponse, PaymentProviderCompletionResponse, TyroTransactionCompletionResponse } from "./PaymentProvider";
import { CustomerTips, PaymentPriceAdjustmentPayload, PaymentTypeId, TillDepositTabAllocations, TransactionSurcharge } from "./transaction";

export enum PurchaseTypeId {
  Purchase = 1,
  Refund = 2,
}
export enum PurchaseRequestStatus {
  CompletedSuccessfully = 2,
  Pending = 1,
  Completed = 0,
}
export type Purchase = {
  _key?: string;
  transactionNumber: number;
  terminalId?: string;
  merchantId?: string;
  tillId?: string;
  functionId?: string;
  requestData?: unknown;
  cashReceived?: number;
  rawResponse?: TyroTransactionCompletionResponse | Mx51TransactionCompletionResponse | Stripe.Refund | Stripe.Charge;
  response?: PaymentProviderCompletionResponse;
  requestStatus: PurchaseRequestStatus;
  retries?: number;
  restaurantId: string;
  id?: string;
  paymentTypeId: PaymentTypeId;
  typeId: PurchaseTypeId;
  operatorId?: string;
  createdAt: number;
  invoiceId?: string;
  accountGroupId?: string;
  accountProductId?: string;
  manualResponse?: boolean;
};

export interface CancelPaymentModel {
  tillId: string;
}

export interface CancelModel {
  date: string;
  restaurantId: string;
  sessionId: string;
  mealId: string;
  operatorId: string;
  tillId: string;
}
//
export type PurchaseModel = {
  cashReceived: number;
  sessionId: string;
  tipAmount: number;
  closeTransaction: boolean;
  paymentTypeId?: PaymentTypeId;
  tillId: string;
  terminalId: string;
  merchantId?: string;
  transactionNumber: number;
  orderNumber: number;
  purchaseAmount: number;
  customerTips?: CustomerTips;
  cashoutAmount: number;
  serviceFee: number;
  promptForCashout: boolean;
  orders?: Orders;
  operatorId: string;
  operatorName?: string;
  redemptionCode?: any;
  label: { labelId: string; version: string };
  menuId?: string;
  date: number;
  closeTabId?: string;
  paymentTabId?: string;
  mealId: string;
  restaurantId: string;
  bookingId?: string;
  functionBookingId?: string;
  functionType?: string;
  customerId?: string;
  giftCertificateCode?: string;
  paymentProviderResponse?: PaymentProviderCompletionResponse;
  rawResponse?: TyroTransactionCompletionResponse | Mx51TransactionCompletionResponse;
  typeId: PurchaseTypeId;
  suppressMerchantPassword?: boolean;
  resbutlerFee?: number;
  accountGroupId?: string;
  accountProductId?: string;
  paymentPriceAdjustmentsPayload: PaymentPriceAdjustmentPayload[];
  depositTabAllocations?: TillDepositTabAllocations;
  depositTabId?: string;
  splitItemPay?: boolean;
};

export interface RefundModel {
  paymentKey: string;
  date: string;
  mealId: string;
  operatorPin: string;
  restaurantId: string;
  tillId: string;
  terminalId: string;
  merchantId?: string;
  transactionNumber: number;
  transactionId: string;
  refundLineItemsAndValues: Array<any>;
  transactionFee?: number;
  serviceFee?: number;
  tipAmount?: number;
  description: string;
  suppressMerchantPassword?: boolean;
  operatorId: string;
  refundAmount: number;
  sessionId: string;
  paymentTypeId: PaymentTypeId;
  paymentProviderResponse?: PaymentProviderCompletionResponse;
  rawResponse?: TyroTransactionCompletionResponse | Mx51TransactionCompletionResponse;
  typeId: PurchaseTypeId;
  refundSurcharges: TransactionSurcharge[];
  refundServiceCharge: TransactionSurcharge;
}

export interface SettlementModel {
  tillId: string;
  isSettlementEnquiry: boolean;
  date?: number;
  format?: string;
  type?: string;
}
